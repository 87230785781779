import React, { useEffect, useState } from "react";

function createNoiseBackground() {
  const canvas = document.createElement("canvas");
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;
  const ctx = canvas.getContext("2d");

  // Generate noise
  for (let x = 0; x < canvas.width; x++) {
    for (let y = 0; y < canvas.height; y++) {
      const r = Math.random() * 10;
      ctx.fillStyle = `rgba(0,0,0,${r / 100})`;
      ctx.fillRect(x, y, 1, 1);
    }
  }

  return canvas.toDataURL();
}

function NoiseBackground() {
  const [noiseBackground, setNoiseBackground] = useState(null);

  useEffect(() => {
    // Generate noise background on mount
    const bgImage = createNoiseBackground();
    setNoiseBackground(bgImage);

    // Optional: Regenerate on window resize
    const handleResize = () => {
      const newBgImage = createNoiseBackground();
      setNoiseBackground(newBgImage);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const backgroundStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: noiseBackground
      ? `url(${noiseBackground}), linear-gradient(to bottom, #123c41, #1f5a60, #123841)`
      : "linear-gradient(to bottom, #123c41, #1f5a60, #123841)",
    backgroundAttachment: "fixed",
    backgroundRepeat: "repeat, no-repeat",
    backgroundSize: "auto, cover",
    zIndex: -1,
    pointerEvents: "none",
  };

  return <div style={backgroundStyle} />;
}

export default NoiseBackground;
