import "./App.css";
import "./resources/components/NavBar/NavBar.jsx";
import Router from "./resources/components/Router/Router.jsx";
import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import Notification from "./resources/components/Notification/Notification.jsx";
import { getVacations } from "./services/db.js";
import NoiseBackground from "./resources/components/NoiseBackground/NouseBackground.jsx";

const date = new Date();

function App() {
  const [, setCookie] = useCookies(["Telpower.com.ar"]);
  const [vacations, setVacations] = useState(null);

  useEffect(() => {
    setCookie("Telpower.com.ar", "value", {
      sameSite: "none",
      secure: true,
    });
  }, [setCookie]);

  useEffect(() => {
    getVacations().then((resp) => {
      setVacations(resp);
    });
  }, []);

  // Función para formatear el mes con ceros a la izquierda
  const formatDate = (dateToFormat) => {
    // Sumamos 1 porque los meses en JavaScript empiezan desde 0
    const monthNumber = dateToFormat + 1;
    // Utilizamos padStart para asegurar que siempre tenga 2 dígitos
    return String(monthNumber).padStart(2, "0");
  };

  // Función para incrementar un mes formateado
  const incrementMonth = (formattedMonth) => {
    const monthNumber = parseInt(formattedMonth);
    return String(monthNumber + 1).padStart(2, "0");
  };

  const currentFormattedMonth = formatDate(date.getMonth());

  return (
    <div>
      <NoiseBackground />
      {vacations &&
        incrementMonth(currentFormattedMonth) ===
          vacations.vacationsFrom.month && (
          <Notification
            title="Vacaciones!"
            description={`No estaremos atendiendo desde el ${vacations.vacationsFrom.formattedDate} hasta el ${vacations.vacationsTo.formattedDate}`}
          />
        )}
      <Router className="backgr" />
    </div>
  );
}

export default App;
