import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  getDoc,
  query,
  where,
  addDoc,
  orderBy,
  writeBatch,
} from "firebase/firestore";
//import { getAnalytics } from "firebase/analytics";
import productos from "../resources/data/prodData2.js";
import { TimestampToDate } from "../utils/utils.js";
import CryptoJS from "crypto-js";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "paginatp-97b98.firebaseapp.com",
  projectId: "paginatp-97b98",
  storageBucket: "paginatp-97b98.appspot.com",
  messagingSenderId: "713157010329",
  appId: "1:713157010329:web:71511fe3b9e20d9f4aa908",
  measurementId: "G-8WZVW9YV3T",
};

const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const db = getFirestore(app);
const TITLE_CARRITO = "Balanceadora de Rueda Montada";

export async function addProducts() {
  const productsRef = collection(db, "products");

  // Crear un array de promesas
  const promises = productos.map(async (producto) => {
    const tableObj = {
      headers: producto.table.headers,
      rows: producto.table.rows.reduce((acc, curr, index) => {
        const rowObj = {};
        for (let i = 0; i < curr.length; i++) {
          rowObj[producto.table.headers[i]] = curr[i];
        }
        acc[index] = rowObj;
        return acc;
      }, {}),
    };
    producto.table = tableObj;
    await addDoc(productsRef, producto);
  });

  // Esperar a que se completen todas las promesas antes de continuar
  await Promise.all(promises);
}

export async function getItems() {
  try {
    const productsRef = collection(db, "products");
    const q = query(productsRef, orderBy("index"));
    const productsSnap = await getDocs(q);
    const documents = productsSnap.docs;
    const docsData = documents.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });
    return docsData;
  } catch (error) {
    alert(`Ocurrió un error: ${error}.`);
    throw new Error("Ocurrio un Error:");
  }
}

export async function getItem(idURL) {
  try {
    const productRef = doc(db, "products", idURL);
    const productSnap = await getDoc(productRef);
    // Amamos al .exists()
    if (productSnap.exists()) {
      return { id: productSnap.id, ...productSnap.data() };
    } else {
      return { index: -1 };
    }
  } catch (error) {
    alert(`Ocurrió un error: ${error}.`);
    throw new Error("Ocurrio un Error:");
  }
}

export async function getRandomItems() {
  try {
    const productRef = collection(db, "products");
    const snapshot = await getDocs(productRef);

    const items = snapshot.docs.map((doc) => {
      const data = doc.data();
      const firstImage =
        data.img && data.img.hasOwnProperty("0") ? data.img["0"] : null;

      return {
        id: doc.id,
        ...data,
        img0: firstImage,
      };
    });

    // Obtener 3 elementos aleatorios sin repetición
    const randomItems = getRandomUniqueElements(items, 3);

    // Agrego siempre carrito al banner

    // Reviso si ya esta agregado en el array de randomItems
    let hasCarrito = false;
    randomItems.forEach((item) => {
      if (item.title === `${TITLE_CARRITO}`) hasCarrito = true;
    });

    // Si no esta agregado, lo agrego desde el array de items que ya tiene toda la data
    const carritoItem = items.filter((item) => {
      if (item.title === `${TITLE_CARRITO}`) {
        return item;
      }
      return null;
    });
    if (!hasCarrito) randomItems.push(carritoItem[0]);
    return randomItems;
  } catch (error) {
    console.error(`Ocurrió un error: ${error}`);
    throw new Error("Ocurrió un error al obtener los elementos aleatorios");
  }
}

// Función para obtener elementos aleatorios sin repetición
function getRandomUniqueElements(arr, num) {
  const shuffled = arr.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, num);
}
// Función para buscar productos por nombre o categoría
export async function getItemsByQuery(queries) {
  try {
    const productsRef = collection(db, "products");
    let titleQuery = null;
    for (let i = 0; i < queries.length; i++) {
      let queryTerm = queries[i];
      let titleWhere = where("title", ">=", queryTerm);
      if (titleQuery == null) {
        titleQuery = query(productsRef, titleWhere);
      } else {
        titleQuery = query(titleQuery, titleWhere);
      }
    }
    let titleDocs = [];
    if (queries.length === 0) {
      // Si queries está vacío, devolver todos los productos
      const allSnap = await getDocs(productsRef);
      titleDocs = allSnap.docs;
    } else {
      // De lo contrario, ejecutar las consultas
      if (titleQuery != null) {
        const titleSnap = await getDocs(titleQuery);
        titleDocs = titleSnap.docs;
      }
    }
    let uniqueItems = {};
    const documents = [...titleDocs];
    documents.forEach((doc) => {
      const id = doc.id;
      if (!uniqueItems[id]) {
        uniqueItems[id] = { id: id, ...doc.data() };
      }
    });
    const uniqueItemsArray = Object.values(uniqueItems);
    return uniqueItemsArray;
  } catch (error) {
    alert(`Ocurrió un error: ${error}.`);
    throw new Error("Ocurrio un Error:");
  }
}

export async function getItemsCateg(categURL) {
  try {
    const productsRef = collection(db, "products");
    const q = query(productsRef, where("categ", "==", categURL));
    const productsSnap = await getDocs(q);
    const documents = productsSnap.docs;
    const docsData = documents.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });
    return docsData;
  } catch (error) {
    alert(`Ocurrió un error: ${error}.`);
    throw new Error("Ocurrio un Error:");
  }
}

export async function getItemsGen(genURL, categURL) {
  try {
    const productsRef = collection(db, "products");
    const q = query(
      productsRef,
      where("gen", "==", genURL),
      where("cat", "==", categURL)
    );
    const productsSnap = await getDocs(q);
    const documents = productsSnap.docs;
    const docsData = documents.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });
    return docsData;
  } catch (error) {
    alert(`Ocurrio un Error: ${error}.`);
    throw new Error("Ocurrio un Error:");
  }
}

export async function createOrder(order) {
  const ordersRef = collection(db, "orders");
  const orderId = await addDoc(ordersRef, order);
  return orderId;
}

export async function createMessage(contactInfo) {
  const ordersRef = collection(db, "messages");
  const messageId = await addDoc(ordersRef, contactInfo);
  return messageId;
}

export async function updateStock(order) {
  const productRef = collection(db, "products");

  const batch = writeBatch(db);
  let item, productDoc, product;
  for (item of order.items) {
    let { id, count } = item;
    productDoc = doc(productRef, id);
    product = await getDoc(productDoc);
    let newStock = product.data().stock - count;
    if (newStock <= 0) {
      alert(`No queda stock del producto ${id}.`);
      throw new Error(`No queda stock del producto ${id}.`);
    } else {
      batch.update(productDoc, { stock: newStock });
    }
  }
  await batch.commit();
}

export async function getTutorials() {
  try {
    const tutorialsRef = collection(db, "tutorials");

    const q = query(tutorialsRef);
    const tutorialsSnap = await getDocs(q);
    const documents = tutorialsSnap.docs;
    const docsData = documents.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });
    return docsData;
  } catch (error) {
    alert(`Ocurrió un error: ${error}.`);
    throw new Error("Ocurrio un Error:");
  }
}
export async function getVacations() {
  try {
    const vacationsRef = collection(db, "settings");
    const q = query(vacationsRef);
    const vacationsSnap = await getDocs(q);
    const documents = vacationsSnap.docs;

    const docsData = documents.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });

    const vacations = {
      vacationsFrom: TimestampToDate(docsData[0].vacationsFrom),
      vacationsTo: TimestampToDate(docsData[0].vacationsTo),
    };
    return vacations;
  } catch (error) {
    alert(`Ocurrió un Error: ${error}.`);
    throw new Error("Ocurrió un Error.");
  }
}

export async function getWebtoothDbVersion() {
  try {
    const webtoothRef = collection(db, "webtoothVersion");
    const q = query(webtoothRef);
    const webtoothSnap = await getDocs(q);
    const documents = webtoothSnap.docs;
    const docsData = documents.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });
    return docsData[0].dbVersion;
  } catch (error) {
    //alert(`Ocurrio un Error: ${error}.`);
    throw new Error("Ocurrio un Error. ", error);
  }
}

export async function getWebtoothDbData(secretKey) {
  try {
    // Obtener los datos de webtoothVehicles
    const webtoothRef = collection(db, "webtoothVehicles");
    const q = query(webtoothRef);
    const webtoothSnap = await getDocs(q);

    // Mapear documentos
    const docsData = webtoothSnap.docs.map((doc) => {
      const data = { id: doc.id, ...doc.data() };

      // Convertir fechas a ISO para compatibilidad
      Object.keys(data).forEach((key) => {
        if (data[key] instanceof Date) {
          data[key] = data[key].toISOString();
        }
      });

      return data;
    });

    // Convertir a JSON con formato legible
    const jsonString = JSON.stringify(docsData, null, 2);

    // Cifrar el JSON (formato compatible con OpenSSL)
    const encrypted = CryptoJS.AES.encrypt(jsonString, secretKey).toString();

    // Crear Blob con datos cifrados
    const blob = new Blob([encrypted], { type: "text/plain" });

    // Crear enlace de descarga
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "webtoothVehicles_backup.enc";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);

    return encrypted;
    //openssl enc -aes-256-cbc -d -in webtoothVehicles_backup.enc -out webtoothVehicles_backup.json
  } catch (error) {
    console.error(`Ocurrió un Error: ${error}`);
    throw new Error("Error en el respaldo seguro");
  }
}

export async function getWebtoothSoftwareVersion() {
  try {
    const webtoothRef = collection(db, "webtoothVersion");
    const q = query(webtoothRef);
    const webtoothSnap = await getDocs(q);
    const documents = webtoothSnap.docs;
    const docsData = documents.map((doc) => {
      return { id: doc.id, ...doc.data() };
    });
    return docsData[0].softwareVersion;
  } catch (error) {
    //alert(`Ocurrio un Error: ${error}.`);
    throw new Error("Ocurrio un Error. ", error);
  }
}
export async function backupData() {
  try {
    // Colecciones a respaldar
    const collectionsToBackup = [
      "products",
      "settings",
      "tutorials",
      "webtoothVersion",
    ];

    // Objeto para almacenar todos los backups
    const backupData = {};

    // Iterar sobre cada colección
    for (const collectionName of collectionsToBackup) {
      const collectionRef = collection(db, collectionName);
      const q = query(collectionRef);
      const snapshot = await getDocs(q);

      // Mapear documentos
      const documents = snapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });

      // Descargar como JSON
      const jsonString = JSON.stringify(documents, null, 2);
      const blob = new Blob([jsonString], { type: "application/json" });
      const url = URL.createObjectURL(blob);

      // Crear enlace de descarga
      const link = document.createElement("a");
      link.href = url;
      link.download = `${collectionName}_backup.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      // Guardar en el objeto de backup
      backupData[collectionName] = documents;
    }

    return backupData;
  } catch (error) {
    console.error("Error en backup:", error);
    alert(`Ocurrió un Error: ${error}.`);
    throw new Error("Ocurrió un Error.");
  }
}
