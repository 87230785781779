import SHA256 from "crypto-js/sha256";
import { backupData } from "../../../services/db";
import { useState } from "react";
function BackupData() {
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const hashedInput = SHA256(password).toString();
    // Comparar con la variable de entorno
    if (hashedInput === process.env.REACT_APP_BACKUP_PASSWORD) {
      try {
        await backupData();
        // Redirigir después de la descarga
        window.location.href = "/";
      } catch (error) {
        console.error("Backup failed", error);
        setError("Error al realizar el backup");
      }
    } else {
      setError("Contraseña incorrecta");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        flexDirection: "column",
      }}
    >
      <h2 style={{ marginBottom: "20px", color: "white" }}>
        Backup de Base de Datos
      </h2>
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Ingrese contraseña de backup"
          style={{
            padding: "10px",
            margin: "10px 10px",
            width: "250px",
          }}
        />
        <button
          type="submit"
          style={{
            padding: "10px 20px",
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            cursor: "pointer",
          }}
        >
          Realizar Backup
        </button>
      </form>
      {error && <p style={{ color: "red", marginTop: "10px" }}>{error}</p>}
    </div>
  );
}

export { BackupData };
